export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Banner Promo',
    route: 'banner-index',
    icon: 'FileIcon',
  },
  {
    title: 'Artikel',
    route: 'artikel-index',
    icon: 'FileIcon',
  },
  {
    title: 'Store',
    route: 'store-index',
    icon: 'FileIcon',
  },
]
